<template>
    <purposesCloudList />
</template>

<script>

import purposesCloudList from '@/components/purpose/purposesCloudList'
import postMixin from '@/mixins/postMixin'
export default {
    name: 'PurposesView',
    components: { purposesCloudList },
    mixins: [postMixin]
}
</script>

<style scoped>

</style>
